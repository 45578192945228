import { useState } from "react";
import Image from "next/image";
import { api } from "~utils/api/fetch";
import Button from "../../ui/button";
import Heading from "../../ui/heading";
import AnimationContainer from "~components/ui/animation-container";

// TODO to env
const backBaseUrl = "https://website.subclub.space";

const EmailBlock = ({ data, locale, className }) => {
  const {
    title,
    title_image,
    placeholder,
    button,
    image,
    success_placeholder,
    success_button,
  } = data;
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.post(`${backBaseUrl}/api/emails`, { data: { email } });
      setEmail("");
      setSuccess(true);
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };

  return (
    <section
      className={`relative bg-black pb-32 pt-72 text-white md:pt-72 md:pb-72 ${className}`}
    >
      <div className="landing-container">
        <Image src={image} layout="fill" objectFit="cover" />
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black" />
        <AnimationContainer
          className="relative flex flex-col items-center"
          delay={150}
        >
          <Heading
            variant="h2"
            image={title_image}
            locale={locale}
            className="mb-16 text-center md:mb-24 md:text-left"
          >
            {title}
          </Heading>
          <div className="relative w-full max-w-[500px] rounded-2xl md:bg-white text-black">
            <form
              className="flex flex-col items-center p-0 md:flex-row md:py-5 md:px-7"
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                name="email"
                value={email}
                placeholder={
                  (success ? success_placeholder : placeholder) || "Email"
                }
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (success) setSuccess(false);
                }}
                className="w-full bg-white text-center text-xl outline-none md:text-left rounded-xl py-4 md:placeholder:text-lg placeholder:text-base"
              />
              <Button
                type="submit"
                className={`mt-4 w-full !rounded-xl !py-2 !px-8 md:mt-0 md:ml-7 md:w-auto ${
                  !success ? "!bg-pink-base" : "!bg-pink-base"
                }`}
              >
                {success ? success_button : button}
              </Button>
            </form>
          </div>
        </AnimationContainer>
      </div>
    </section>
  );
};

export default EmailBlock;
