import Link from "next/link";
import { useState } from "react";
import AllIcons from "~components/ui/all-icons";
import Button from "../../ui/button";

const Card = ({ data }) => {
  const { description, caption, button, unit, min, max, default_value } = data;
  const [value, setValue] = useState(default_value || 1);

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (newValue % 1 !== 0) newValue = Math.floor(newValue);
    else if (newValue > max) newValue = max;
    else if (newValue < min) newValue = min;
    setValue(newValue);
  };

  return (
    <div className="flex flex-col items-center text-center rounded-2xl border-2 border-white/50   p-8 md:p-5">
      <div className="flex flex-col items-center text-center pointer-events-none">
        <div className={"mb-20 max-w-[80%] text-base text-white/70 md:text-xl"}>
          {description}
        </div>
        <div
          className="mb-4 text-5xl"
          // style={{ transform: `scale(${1 + value * 0.001})` }}
        >
          <span className="rainbow-text-2 font-bold opacity-50">
            {value || 1}
            {unit}
          </span>
        </div>
        <div className="w-full lg:px-12">
          <div className="mb-4 w-full">
            <input
              type="number"
              value={value}
              placeholder={unit}
              className="w-full rounded-full border border-white/20 bg-transparent py-2 px-4 font-medium text-white/70 placeholder:text-white/50"
              onChange={handleChange}
            />
          </div>
          <div className="mb-8 w-full opacity-50">
            <div className="mb-1 flex flex-row justify-between">
              {[min, max].map((n) => (
                <div key={n}>
                  {n}
                  {unit}
                </div>
              ))}
            </div>
            <div className="relative h-5">
              <div className="absolute top-1 left-0 h-2.5 w-full rounded-full bg-white/50" />
              <div
                className="absolute top-1 left-0 h-2.5 rounded-full bg-pink-base"
                style={{ width: `${(value / max) * 100}%` }}
              />
              <div
                className="absolute -top-[1.5px] "
                style={{ left: `${(value / max) * 100 - 2}%` }}
              >
                <div className="h-5 w-5 rounded-full border-[4px] border-pink-base bg-white" />
              </div>
              <div className="absolute left-0 right-0 top-0 opacity-0">
                <input
                  type="range"
                  step={1}
                  min={min}
                  max={max}
                  value={value}
                  className="w-full"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Button className="mb-5 flex w-full max-w-[280px] flex-row items-center justify-center !bg-lime-base !text-black">
          {button}
          <AllIcons name="ArrowRight" className="ml-2" />
        </Button>
      </div>
      <Link passHref href={"/help-center#cloud-storage"}>
        <a className="text-sm opacity-50">{caption}</a>
      </Link>
    </div>
  );
};

export default Card;
