import MainBlock from "~components/landing/blocks/main";
import IntroBlock from "~components/landing/blocks/intro";
import VerificationBlock from "~components/landing/blocks/verification";
import BetterBlock from "~components/landing/blocks/better";
import ReferralBlock from "~components/landing/blocks/referral";
import ComparsionBlock from "~components/landing/blocks/comparsion";
import StorageBlock2 from "~components/landing/blocks/storage-2";
import EmailBlock from "~components/landing/blocks/email";
import getMainPageProps from "~components/landing/get-main-page-props";
import LandingLayout from "~components/landing/layout";

const Index = ({ data, header, footer, locale }) => {
  return (
    <LandingLayout header={header} footer={footer} locale={locale}>
      {data?.length
        ? data.map((block) => {
            const key = block.__component;
            const PageBlock = components[key];
            if (!PageBlock) return null;
            return <PageBlock key={key} data={block} locale={locale} />;
          })
        : null}
    </LandingLayout>
  );
};

export const getStaticProps = getMainPageProps;

export default Index;

const components = {
  ["page-blocks.main-block"]: MainBlock,
  ["page-blocks.intro-block"]: IntroBlock,
  ["page-blocks.verification-block"]: VerificationBlock,
  ["page-blocks.better-block"]: BetterBlock,
  ["page-blocks.referral-block"]: ReferralBlock,
  ["page-blocks.comparsion-block"]: ComparsionBlock,
  ["page-blocks.storage-block-2"]: StorageBlock2,
  ["page-blocks.email-block"]: EmailBlock,
};
