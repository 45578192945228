import Image from "next/image";
import useBreakpoint from "src/hooks/use-breakpoint";
import AnimationContainer from "~components/ui/animation-container";
import Heading from "../../ui/heading";
import Card from "./card";
import defaultData from "./data";

const StorageBlock = ({ data = defaultData, locale, className }) => {
  const isMd = useBreakpoint("md");
  const { title, title_image, description, card, image } = data;

  return (
    <section
      className={`relative z-[1] -mt-8 bg-black pt-16 lg:pt-4 ${className}`}
    >
      <div className="landing-container text-white">
        <AnimationContainer className="mb-8 md:mb-16" delay={150}>
          <Heading
            variant="h2"
            image={title_image}
            locale={locale}
            className="mb-5"
          >
            {title}
          </Heading>
          <p className="text-lg md:w-3/5 md:text-xl">{description}</p>
        </AnimationContainer>
        <div className="flex flex-col gap-6 md:flex-row">
          <AnimationContainer
            className="flex-1 " // pointer-events-none
            delay={150}
          >
            <Card data={card} />
          </AnimationContainer>
          <AnimationContainer className="relative w-full flex-1" delay={300}>
            {isMd ? (
              <Image
                alt=""
                src={image.desktop}
                layout="fill"
                objectFit="contain"
                className="transition-all hover:scale-95"
              />
            ) : (
              <Image
                alt=""
                src={image.mobile}
                width={7}
                height={9}
                layout="responsive"
                objectFit="contain"
                className="transition-all hover:scale-95"
              />
            )}
          </AnimationContainer>
        </div>
      </div>
    </section>
  );
};

export default StorageBlock;
