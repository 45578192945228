import { Fragment } from "react";
import Image from "next/image";
import AllIcons from "~components/ui/all-icons";
import Markdown from "~ui/markdown";
import defaultData from "./data";
import AnimationContainer from "~components/ui/animation-container";

const ComparsionBlock = ({ data = defaultData, className, locale }) => {
  const { image, images, cards } = data;

  return (
    <section
      className={`relative bg-gray-back pt-20 pb-[384px] md:pb-[512px] md:pt-32 ${className}`}
    >
      <div className="landing-container relative flex justify-center">
        <div className="absolute h-full w-full scale-150">
          <Image alt="" src={image} layout="fill" objectFit="contain" />
        </div>
        <div className="flex flex-col md:flex-row">
          {cards.map((card, index) => (
            <AnimationContainer key={card.title} delay={(index + 1) * 150}>
              <div
                className={
                  !index
                    ? "relative z-[2] ml-10 rotate-2 md:mt-44 md:ml-0 md:-rotate-6"
                    : "relative z-[1] -mt-6 -rotate-2 md:-ml-2 md:mt-0 md:rotate-3"
                }
              >
                <div
                  className={`absolute h-48 w-48 md:h-64 md:w-64 ${
                    !index
                      ? "-top-36 -left-20 -rotate-2 md:-top-44 md:-left-40 md:rotate-6"
                      : "-bottom-40 -right-8 rotate-2 md:-bottom-24 md:-right-48 md:-rotate-3"
                  }`}
                >
                  {images?.[index] && (
                    <Image
                      src={images[index]}
                      layout="fill"
                      objectFit="contain"
                    />
                  )}
                </div>
                <div className="relative z-[1] w-full max-w-[320px] rounded-2xl bg-white p-2 md:max-w-[480px]">
                  <div
                    className={`rotate md:px-px-8 rounded-2xl py-8 px-6 text-white md:px-12 md:py-16
                    ${!index ? "bg-pink-base" : "bg-black/80"}`}
                  >
                    {card.items.map((item) => (
                      <div
                        key={item.image}
                        className="mb-6 flex flex-row items-start last:mb-0"
                      >
                        <div className="mr-4">
                          <Image
                            alt=""
                            src={item.image}
                            width={26}
                            height={26}
                            quality={100}
                            layout="fixed"
                          />
                        </div>
                        <div
                          className={`text-base font-medium md:text-xl
                          ${index && "opacity-70"} `}
                        >
                          {item.text}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className={`flex h-12 items-center justify-center text-lg ${
                      locale === "en"
                        ? "font-heading uppercase"
                        : "font-montserrat text-xl font-bold !leading-5"
                    }`}
                  >
                    {!index ? (
                      <AllIcons name="LogoFull" />
                    ) : (
                      <Markdown
                        components={{
                          p: Fragment,
                          strong: ({ node, ...props }) => (
                            <span
                              className={locale === "en" && "font-heading-wide"}
                              {...props}
                            />
                          ),
                        }}
                      >
                        {card.title}
                      </Markdown>
                    )}
                  </div>
                </div>
              </div>
            </AnimationContainer>
          ))}
        </div>
      </div>
      <img
        src="/assets/images/landing-black.png"
        className="absolute bottom-0 left-1/2 max-h-64 min-h-24 w-full min-w-[720px] -translate-x-[50.05%]"
      />
    </section>
  );
};

export default ComparsionBlock;
