const data = {
  title: "What is Subclub?",
  description:
    "Subclub is a platform for authors to provide paid subscriptions for their fans, store files in extensive and secure cloud storage and get income in cryptocurrency",
  comment: {
    image: "/assets/images/background.jpg",
    text: "Fans can sign up for a monthly subscription to access exclusive content provided by authors, support their work and even earn money by inviting friends through our referral program. In return, authors get monthly income in cryptocurrency, which means freedom and stability.",
  },
  extra: {
    text: "Authors can create content and upload their materials to our extensive and secure cloud storage in full size with no compression.",
    button: "Get started",
  },
  image: {
    desktop: "/assets/images/background.jpg",
    mobile: "/assets/images/background.jpg",
  },
};

export default data;
