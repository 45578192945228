import Link from "next/link";
import Image from "next/image";
import AllIcons from "~components/ui/all-icons";

const defaultMessage = "How to install\nMetamask wallet?";
const defaultLink = "https://www.youtube.com/watch?v=GNPz-Dv5BjM";

const MetamaskMessage = ({ message, link, className }) => {
  const [message1, message2] = (message || defaultMessage).split(/\n/);

  return (
    <Link href={link || defaultLink}>
      <a
        target="_blank"
        className={`relative flex w-min cursor-pointer flex-row items-center whitespace-nowrap rounded-full border border-black px-3 py-1 text-sm font-medium transition-transform hover:-rotate-2 ${className}`}
      >
        <AllIcons
          name="MetamaskLines"
          className="absolute -top-full left-1/2 -translate-x-1/2"
        />
        {message1}
        <span className="mx-1 h-4 w-4">
          <Image src="/assets/images/metamask.png" width={16} height={16} />
        </span>
        {message2}
      </a>
    </Link>
  );
};

export default MetamaskMessage;
