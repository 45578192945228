const data = {
  image: "/assets/images/background.jpg",
  cards: [
    {
      title: "",
      items: [
        {
          image: "/assets/images/background.jpg",
          text: "No registration",
        },
        {
          image: "/assets/images/background.jpg",
          text: "No verification",
        },
        {
          image: "/assets/images/background.jpg",
          text: "Anonymity",
        },
        {
          image: "/assets/images/background.jpg",
          text: "Low commission",
        },
        {
          image: "/images/blocks/comparsion/card-icon-1-5.png",
          text: "Money is transferred directly to your wallet, platform can’t access them",
        },
      ],
    },
    {
      title: "Other product",
      items: [
        {
          image: "/assets/images/background.jpg",
          text: "Long and time-consuming registration",
        },
        {
          image: "/assets/images/background.jpg",
          text: "Uploading documents for identity verification",
        },
        {
          image: "/assets/images/background.jpg",
          text: "The need to link a bank card",
        },
        {
          image: "/assets/images/background.jpg",
          text: "Accepting payments and getting income in fiat",
        },
        {
          image: "/assets/images/background.jpg",
          text: "High platform fees",
        },
        {
          image: "/assets/images/background.jpg",
          text: "The platform has access to your funds and can freeze them",
        },
      ],
    },
  ],
};

export default data;
