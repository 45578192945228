import { useMemo } from "react";
import AnimationContainer from "~ui/animation-container";
import Heading from "../../ui/heading";
import Stories from "../../ui/stories";
import Video from "../../ui/video";
import defaultData from "./data";

const STORY_SIZE = {
  width: 250,
  height: 400,
};

const MainBlock = ({ data = defaultData, locale, className }) => {
  const { title, title_image, description, images, videos } = data;

  const items = useMemo(() => {
    const [imgs, vids] = [images || [], videos || []];
    return [...vids.slice(0, 2), imgs, ...vids.slice(2, 3)];
  }, [images, videos]);

  return (
    <section className={`bg-white pt-20 pb-10 md:pt-24 ${className}`}>
      <div className="landing-container">
        <AnimationContainer
          className="mb-16 flex flex-col items-center text-center md:mb-20"
          delay={150}
        >
          <Heading
            variant="h1"
            image={title_image}
            locale={locale}
            className="mb-5 w-full"
            imageClassName="mx-auto"
          >
            {title}
          </Heading>
          <p className="rainbow-text text-xl sm:w-3/4 md:whitespace-pre-line md:text-2xl">
            {description}
          </p>
        </AnimationContainer>
        <AnimationContainer className="-mx-4 flex justify-center" delay={300}>
          <div className="flex snap-x snap-proximity gap-2 overflow-x-auto px-4">
            {items?.length
              ? items.map((item, index) => (
                  <div
                    // TODO Update keys
                    key={index.toString()}
                    className="snap-center overflow-hidden rounded-2xl transition-all hover:scale-95"
                    style={{ minWidth: STORY_SIZE.width }}
                  >
                    {Array.isArray(item) ? (
                      item.length ? (
                        <Stories stories={item} {...STORY_SIZE} />
                      ) : null
                    ) : (
                      item && <Video data={item} {...STORY_SIZE} />
                    )}
                  </div>
                ))
              : null}
          </div>
        </AnimationContainer>
      </div>
    </section>
  );
};

export default MainBlock;
