const data = {
  title: "No verification",
  description: "No registration and verification — start earning in 3 steps!",
  items: [
    {
      image: "/assets/images/background.jpg",
      text: "Log in to the platform via your Web3 wallet",
    },
    {
      image: "/assets/images/background.jpg",
      text: "Add a link to Subclub on your social networks",
    },
    {
      image: "/assets/images/background.jpg",
      text: "Create content and get stable monthly income",
    },
  ],
  image: "/assets/images/background.jpg",
};

export default data;
