import { Fragment } from "react";
import Image from "next/image";
import Heading from "../../ui/heading";
import Markdown from "~ui/markdown";
import MetamaskMessage from "./metamask-message";
import defaultData from "./data";
import AnimationContainer from "~components/ui/animation-container";

const VerificationBlock = ({ data = defaultData, locale, className }) => {
  const {
    title,
    title_image,
    description,
    items,
    image,
    metamask_message,
    metamask_link,
  } = data;

  return (
    <section
      className={`bg-gray-back pt-20 pb-20 md:pb-28 md:pt-36 ${className}`}
    >
      <div className="landing-container">
        <AnimationContainer delay={150}>
          <div className="mb-8 text-center">
            <Heading
              variant="h2"
              image={title_image}
              locale={locale}
              className="mb-5"
            >
              {title}
            </Heading>
            <p className="text-lg md:text-2xl">{description}</p>
          </div>
          <div className="mb-8 flex justify-center">
            <MetamaskMessage message={metamask_message} link={metamask_link} />
          </div>
        </AnimationContainer>
        <div className="flex flex-col items-center justify-between gap-20 md:flex-row">
          <AnimationContainer
            className="flex flex-1 flex-col gap-4 md:gap-8"
            delay={150}
          >
            {items.map((item) => (
              <div
                key={item.image}
                className="relative flex flex-row items-center"
              >
                <div className="relative mr-5 min-w-[90px] max-w-[90px] md:min-w-[120px] md:max-w-[120px] transition-all hover:scale-110">
                  <Image
                    alt=""
                    src={item.image}
                    width={120}
                    height={120}
                    quality={100}
                    objectFit="cover"
                    className="h-5 w-5 rounded-2xl"
                  />
                </div>
                <Markdown
                  className={`text-xl text-black/50 md:text-2xl ${
                    locale === "en" ? "font-medium" : "font-semibold"
                  }`}
                  components={{
                    p: Fragment,
                    strong: ({ node, ...props }) => (
                      <span className="rainbow-text-2" {...props} />
                    ),
                    em: ({ node, ...props }) => (
                      <span className="text-black" {...props} />
                    ),
                  }}
                >
                  {item.text}
                </Markdown>
              </div>
            ))}
          </AnimationContainer>
          <AnimationContainer
            className="relative -mr-8 -mt-8 w-full flex-1 md:mt-0 md:mr-0"
            delay={300}
          >
            <Image
              alt=""
              src={image}
              width={3}
              height={2}
              layout="responsive"
              objectFit="contain"
            />
          </AnimationContainer>
        </div>
      </div>
    </section>
  );
};

export default VerificationBlock;
