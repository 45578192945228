import Image from "next/image";
import Link from "next/link";
import useBreakpoint from "~hooks/use-breakpoint";
import AnimationContainer from "~ui/animation-container";
import AllIcons from "~ui/all-icons";
import Button from "../../ui/button";
import Heading from "../../ui/heading";
import Markdown from "~ui/markdown";
import defaultData from "./data";

const IntroBlock = ({ data = defaultData, locale, className }) => {
  const isMd = useBreakpoint("md");
  const { title, title_image, description, comment, extra, image } = data;

  return (
    <section
      className={`relative bg-gray-back pt-48 pb-20 md:pb-36 lg:pt-80 ${className}`}
    >
      <img
        src="/assets/images/landing-white.png"
        className="absolute top-0 left-1/2 max-h-64 min-h-24 w-full min-w-[720px] -translate-x-[50.05%]"
      />

      <div className="landing-container flex flex-col items-center justify-center gap-20 md:flex-row">
        <AnimationContainer className="flex flex-1 flex-col" delay={150}>
          <div className="mb-8 flex flex-col">
            <Heading
              variant="h2"
              image={title_image}
              locale={locale}
              className="mb-5"
            >
              {title}
            </Heading>
            <div className="whitespace-pre-line">
              <Markdown
                components={{
                  strong: ({ node, ...props }) => (
                    <span className="rainbow-text-1" {...props} />
                  ),
                }}
                className={`text-lg sm:text-2xl ${
                  locale !== "en" && "!leading-8"
                }`}
              >
                {description}
              </Markdown>
            </div>
          </div>
          <div className="mb-8 flex flex-row">
            <div className="relative mr-3 min-w-16">
              <Image
                alt=""
                src={comment.image}
                layout="fill"
                className="rounded-full object-cover"
              />
            </div>
            <div className="relative">
              <div className="relative z-[1] rounded-2xl border border-gray-200 bg-white py-4 px-5 text-base !leading-snug text-black/70 md:text-lg">
                {comment.text}
              </div>
              <AllIcons
                name="MessageTail"
                className="absolute bottom-0 -left-2"
              />
            </div>
          </div>
          <div className="flex flex-col items-stretch md:flex-row">
            <div className="mb-8 rounded-2xl border border-black py-4 px-5 text-base !leading-snug sm:text-lg md:mb-0 md:mr-8">
              {extra.text}
            </div>
            <Link href="/home" passHref>
              <a className="overflow-hidden items-shrink relative flex min-h-[100px] min-w-[160px] rounded-2xl !border-none bg-black !px-8 text-white transition-all hover:scale-95 md:h-auto">
                <Button className="!hover:scale-100 w-full !p-0">
                  <span className="relative">
                    {extra.button}
                    <AllIcons
                      name="ButtonGraphicsStar"
                      className="absolute -top-2 -right-4"
                    />
                  </span>
                </Button>
                <AllIcons
                  name="ButtonGraphics"
                  className="absolute -bottom-[1px] -right-[1px]"
                />
              </a>
            </Link>
          </div>
        </AnimationContainer>
        <AnimationContainer className="relative w-full flex-1" delay={300}>
          {isMd ? (
            <Image
              alt=""
              src={image.desktop}
              width={1}
              height={1}
              layout="responsive"
              objectFit="contain"
            />
          ) : (
            <Image
              alt=""
              src={image.mobile}
              width={1}
              height={1}
              layout="responsive"
              objectFit="contain"
            />
          )}
        </AnimationContainer>
      </div>
    </section>
  );
};

export default IntroBlock;
