const data = {
  title: "Cloud storage",
  description:
    "Free for every user. If you need more space, you can expand the storage for a fee. We support most file formats without size restrictions. Upload your photos, videos, courses, lectures and podcasts in JPG, MP4, WAV, PSD, etc.",
  image: {
    desktop: "/assets/images/background.jpg",
    mobile: "/assets/images/background.jpg",
  },
  card: {
    min_value: 1,
    max_value: 500,
    default_value: 12,
    unit: "Gb",
    description:
      "In Subclub cloud storage, you can pay for exactly as much space as you need",
    caption:
      "Learn more about how Subclub storage helps protect copyrighted content",
    button: "Soon",
  },
};

export default data;
