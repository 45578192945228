const data = {
  title: "Change the rules!",
  description:
    "Connect with your audience on a new level with the new crypto network for authors and their fans.",
  images: [
    "/assets/images/background.jpg",
    "/assets/images/background.jpg",
    "/assets/images/background.jpg",
    "/assets/images/background.jpg",
  ],
};

export default data;
