const data = {
  title: "Referral program",
  description:
    "A win-win situation for everyone — more subscribers for authors, more bonuses and discounts for fans!  People invited to subscribe to a certain author by someone's referral link will receive a personal discount on the subscription, and the link owner will receive a monthly bonus from each invited user",
  items: [
    {
      image: "/assets/images/background.jpg",
      text: "Creators do not lose anything and receive the amount of money that they have set as the subscription price",
    },
    {
      image: "/assets/images/background.jpg",
      text: "Fans benefit by expanding the community with their own hands and have a direct impact on their favorite author’s growth and success.",
    },
  ],
  button: "Try it now!",
  card_image: "/assets/images/background.jpg",
  image: {
    desktop: "/assets/images/background.jpg",
    mobile: "/assets/images/background.jpg",
  },
};

export default data;
