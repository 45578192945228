import { useRef, useState } from "react";
import Image from "next/image";

const Video = ({ data, width, height, className }) => {
  const { poster, video } = data || {};
  const ref = useRef(null);
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        ref.current.play();
        if (!hovered) setHovered(true);
      }}
      onMouseLeave={() => {
        ref.current.pause();
      }}
      style={{ width, height }}
      className={className}
    >
      {!hovered && poster && (
        <Image
          src={poster}
          width={width}
          height={height}
          objectFit="cover"
          // placeholder="blur"
          // // #dddddd (hex color)
          // blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Ww8AAj8BXkQ+xPEAAAAASUVORK5CYII="
        />
      )}
      <video
        loop
        muted
        ref={ref}
        src={video}
        className="h-full w-full object-cover"
      />
    </div>
  );
};

export default Video;
